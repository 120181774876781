import local from './local'
import mainnet from './mainnet'
import rinkeby from './rinkeby'
import xdai from './xdai'
import energy_web_chain from './energy_web_chain'
import volta from './volta'
import polygon from './polygon'
import bsc from './bsc'

export default {
  local,
  // mainnet,
  // rinkeby,
  // xdai,
  // energy_web_chain,
  // volta,
  // polygon,
  bsc,
}
